$(document).ready(function () {
  ("use strict");

  //Formulari de registre, validacions de camps
  //Validacions de correu
  var email = $("form#form_registre #email");
  var confirm_email = $("form#form_registre #email_bis");

  if (email.length && confirm_email.length) {
    // Utilitzem l'esdeveniment 'input' per detectar canvis a l'input de la contrasenya
    email.on('input', function () {
        if (this.checkValidity() && this.value !== '') {
          confirm_email.attr('pattern', this.value);
          confirm_email.attr('required', 'required');
        } else {
          confirm_email.attr('pattern', '');
          confirm_email.attr('required', false);
        }
    });

    // Afegim un missatge d'error personalitzat
    confirm_email.on('invalid', function () {
        this.setCustomValidity('Las direcciones de correo deben coincidir');
    });

    // Eliminem el missatge d'error quan les contrasenyes coincideixen
    confirm_email.on('input', function () {
        this.setCustomValidity('');
    });
  }

  //Validacions de password
  var password = $("form#form_registre #password");
  var confirm_password = $("form#form_registre #password_bis");

  if (password.length && confirm_password.length) {
      // Utilitzem l'esdeveniment 'input' per detectar canvis a l'input de la contrasenya
      password.on('input', function () {
          if (this.checkValidity() && this.value !== '') {
              confirm_password.attr('pattern', this.value);
              confirm_password.attr('required', 'required');
          } else {
              confirm_password.attr('pattern', '');
              confirm_password.attr('required', false);
          }
      });

      // Afegim un missatge d'error personalitzat
      confirm_password.on('invalid', function () {
          this.setCustomValidity('Las contraseñas deben coincidir');
      });

      // Eliminem el missatge d'error quan les contrasenyes coincideixen
      confirm_password.on('input', function () {
          this.setCustomValidity('');
      });
  }

  // Menú: afegim botó i ocultem menú quan hi ha js
  $(".login-toggle")
    .before(
      '<button class="menu-toggle upp has-text-weight-bold is-size-9" type="button" aria-expanded="false" aria-controls="menu">Menú</button>'
    );
  $("#menu").attr("hidden", "true");

  // ----
  // menú (https://polypane.rocks/blog/the-perfect-responsive-menu-2019/)
  // ----
  var toggleMenu = document.querySelector(".menu-toggle");
  var menu = document.querySelector("#menu");

  if (toggleMenu) {
    toggleMenu.addEventListener("click", function () {
      var open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
      toggleMenu.setAttribute("aria-expanded", !open);
      menu.hidden = !menu.hidden;
    });
  }

  $(".menu-toggle").on("click", function () {
    // afegir classe al botó
    $(this).toggleClass("is-active");
    // afegir classe al body
    $("body").toggleClass("js-menu-open");
  });

  // -------------------------------
  // Menú principal amb desplegables (https://www.w3.org/WAI/tutorials/menus/flyout/#use-button-as-toggle)
  // -------------------------------

  var menuItems = document.querySelectorAll("#menu li.has-submenu");
  Array.prototype.forEach.call(menuItems, function (el, i) {
    var activatingA = el.querySelector("a");
    var btn =
      '<button><span><span class="is-sr-only">Muestra el submenú para “' +
      activatingA.text +
      "”</span></span></button>";
    activatingA.insertAdjacentHTML("afterend", btn);
    // activatingA.setAttribute("aria-expanded", "false");

    el.querySelector("button").addEventListener("click", function (event) {
      var currentMenu = this.parentNode;
      Array.prototype.forEach.call(menuItems, function (el, i) {
        if (currentMenu !== el) {
          el.classList.remove("open");
          el.classList.add("close");
          //el.querySelector("a").setAttribute("aria-expanded", "false");
          el.querySelector("button").setAttribute("aria-expanded", "false");
        }
      });
      //console.log(this.parentNode.className);
      if (this.parentNode.classList.contains("close")) {
        this.parentNode.classList.add("open");
        this.parentNode.classList.remove("close");
        /*this.parentNode
          .querySelector("a")
          .setAttribute("aria-expanded", "true");*/
        this.parentNode
          .querySelector("button")
          .setAttribute("aria-expanded", "true");
      } else {
        this.parentNode.classList.add("close");
        this.parentNode.classList.remove("open");
        /*this.parentNode
          .querySelector("a")
          .setAttribute("aria-expanded", "false");*/
        this.parentNode
          .querySelector("button")
          .setAttribute("aria-expanded", "false");
      }
      event.preventDefault();
    });
  });

  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function (event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function () {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // ----------
  // scroll top
  // ----------
  $(window).scroll(function () {
    if ($(this).scrollTop() > 400) {
      $(".scrolltop").addClass("visible");
    } else {
      $(".scrolltop").removeClass("visible");
    }
  });

  // ---------------------------
  // Tooltips taula de resultats
  // ---------------------------
  // Per evitar que els borders de la taula es solapin amb els tooltips, ja que no hi havia manera de fer-ho per CSS
  $(".icons-container__icon").on("mouseenter", function () {
    $(this).closest("tr").next().css("z-index", "0");
  });
  $(".icons-container__icon").on("mouseleave", function () {
    $(this).closest("tr").next().css("z-index", "");
  });

  // -----------
  // Pop up form
  // -----------
  $('.open-login').magnificPopup({
		type: 'inline',
		preloader: false,
    focus: '#usuari2',
    closeMarkup: '<button type="button" class="mfp-close is-flex is-align-items-center is-justify-content-center has-background-white" aria-label="%title%"><img src="/media/img/ico-tancar.svg" alt="" width="19" height="19" class="mfp-close"></button>',
    tClose: 'Cerrar (Esc)',
    tLoading: 'Cargando...',

		// When elemened is focused, some mobile browsers in some cases zoom in
		// It looks not nice, so we disable it:
		callbacks: {
			beforeOpen: function() {
				if($(window).width() < 700) {
					this.st.focus = false;
				} else {
					this.st.focus = '#usuari2';
				}
			}
		}
	});

  // ------
  // Slider
  // ------

  $(".home-slider").slick({
    dots: true,
    arrows: false,
   // autoplay: true,
   // autoplaySpeed: 1000,
   // speed: 10000,
   draggable: false,
    variableWidth: true,
    adaptiveHeight: true,
    customPaging: function (slider, i) {
      var thumb = $(slider.$slides[i]).data();
      // return "<a href>" + (i + 1) + "</a>";
      return (
        "<button type='button'>" +
        "<span class='slick-sr-only'>" +
        "</span></button>"
      );
    },
  });

  // $('.slider').slick({
  //   dots: true
  // });

  // --------
  // headroom
  // --------
  // $(".main-header").headroom(
    // {
    // offset: 0,
    // tolerance: 5,
    // classes: {
    //   initial: "animated",
    //   pinned: "slideDown",
    //   unpinned: "slideUp"
    // }
  // }
  // );

  // -----------
  // File upload
  // -----------
  $('input[type="file"]').change(function () {
    var i = $(this).next().find(".file-label").clone();
    var file = $('input[type="file"]')[0].files[0].name;
    $(this).next().find(".file-label").text(file);
  });

  // ----
  // Tabs https://inclusive-components.design/tabbed-interfaces/
  // ----
  if ($(".tabbed").length > 0) {
    $(".tabbed").each(function (index, element) {
      // Get relevant elements and collections
      var tabbed = element;
      var tablist = tabbed.querySelector("ul.tab-menu");
      var tabs = tablist.querySelectorAll("a");
      var panels = tabbed.querySelectorAll(".tab-panel");

      // The tab switching function
      var switchTab = function switchTab(oldTab, newTab) {
        newTab.focus(); // Make the active tab focusable by the user (Tab key)

        newTab.removeAttribute("tabindex"); // Set the selected state

        newTab.setAttribute("aria-selected", "true");
        oldTab.removeAttribute("aria-selected");
        oldTab.setAttribute("tabindex", "-1"); // Get the indices of the new and old tabs to find the correct
        // tab panels to show and hide

        var index = Array.prototype.indexOf.call(tabs, newTab);
        var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
        panels[oldIndex].hidden = true;
        panels[index].hidden = false;
      }; // Add the tablist role to the first <ul> in the .tabbed container

      tablist.setAttribute("role", "tablist"); // Add semantics are remove user focusability for each tab

      Array.prototype.forEach.call(tabs, function (tab, i) {
        tab.setAttribute("role", "tab");
        //	    tab.setAttribute("id", "tab" + (i + 1));
        tab.setAttribute("tabindex", "-1");
        tab.parentNode.setAttribute("role", "presentation"); // Handle clicking of tabs for mouse users

        tab.addEventListener("click", function (e) {
          e.preventDefault();
          var currentTab = tablist.querySelector("[aria-selected]");

          if (e.currentTarget !== currentTab) {
            switchTab(currentTab, e.currentTarget);
          }
        }); // Handle keydown events for keyboard users

        tab.addEventListener("keydown", function (e) {
          // Get the index of the current tab in the tabs node list
          var index = Array.prototype.indexOf.call(tabs, e.currentTarget); // Work out which key the user is pressing and
          // Calculate the new tab's index where appropriate

          var dir =
            e.which === 37
              ? index - 1
              : e.which === 39
                ? index + 1
                : e.which === 40
                  ? "down"
                  : null;

          if (dir !== null) {
            e.preventDefault(); // If the down key is pressed, move focus to the open panel,
            // otherwise switch to the adjacent tab

            dir === "down"
              ? panels[i].focus()
              : tabs[dir]
                ? switchTab(e.currentTarget, tabs[dir])
                : void 0;
          }
        });
      }); // Add tab panel semantics and hide them all

      Array.prototype.forEach.call(panels, function (panel, i) {
        panel.setAttribute("role", "tabpanel");
        panel.setAttribute("tabindex", "-1");
        var id = panel.getAttribute("id");
        panel.setAttribute("aria-labelledby", tabs[i].id);
        panel.hidden = true;
      }); // Initially activate the first tab and reveal the first tab panel

      tabs[0].removeAttribute("tabindex");
      tabs[0].setAttribute("aria-selected", "true");
      panels[0].hidden = false;
    });
  }

});

// -----------
// Collapsible (https://inclusive-components.design/collapsible-sections/)
// -----------
(function () {
  // Get all the headings
  const headings = document.querySelectorAll('.collapsible__title')

  Array.prototype.forEach.call(headings, heading => {
    // Give each <h2> a toggle button child
    // with the SVG plus/minus icon
    heading.innerHTML = `
      <button aria-expanded="false" class="is-flex is-justify-content-space-between is-align-items-center gap-2 p-4">
        ${heading.textContent}
        <svg class="is-flex-shrink-0" aria-hidden="true" focusable="false">
          <g fill="none" fill-rule="evenodd">
            <path stroke="#FF8900" stroke-width="2" fill="#FFF" d="M1 44h43V1H1z"/>
            <path stroke="#000" stroke-width="2.5" stroke-linecap="square" d="m17 19 6 6 6-6"/>
          </g>
        </svg>
      </button>
    `
    // Function to create a node list
    // of the content between this heading and the next
    const getContent = (elem) => {
      let elems = []
      while (elem.nextElementSibling && elem.nextElementSibling.className !== 'collapsible__title') {
        elems.push(elem.nextElementSibling)
        elem = elem.nextElementSibling
      }

      // Delete the old versions of the content nodes
      elems.forEach((node) => {
        node.parentNode.removeChild(node)
      })

      return elems
    }

    // Assign the contents to be expanded/collapsed (array)
    let contents = getContent(heading)

    // Create a wrapper element for `contents` and hide it
    let wrapper = document.createElement('div')
    wrapper.hidden = true

    // Add each element of `contents` to `wrapper`
    contents.forEach(node => {
      wrapper.appendChild(node)
    })

    // Add the wrapped content back into the DOM
    // after the heading
    heading.parentNode.insertBefore(wrapper, heading.nextElementSibling)

    // Assign the button
    let btn = heading.querySelector('button')

    btn.onclick = () => {
      // Cast the state as a boolean
      let expanded = btn.getAttribute('aria-expanded') === 'true' || false

      // Switch the state
      btn.setAttribute('aria-expanded', !expanded)
      // Switch the content's visibility
      wrapper.hidden = expanded
    }
  })

  if ($('.collapsible__title').length > 0 && window.location.hash.indexOf('#entry-') > -1 && $(window.location.hash).length > 0) {
    $(window.location.hash).find('button').attr('aria-expanded', true);
    $(window.location.hash).next().show();
    $('html, body').animate({
        scrollTop: $(window.location.hash).parent().offset().top
    }, 1000);
  }

})();
